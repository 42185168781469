import { ComponentProps } from 'react';

import { GetStaticProps } from 'next';

import HomePage from '@components/Pages/HomePage';
import { getRootCategoriesData } from '@services/categories/api';

export const getStaticProps: GetStaticProps<
  ComponentProps<typeof HomePage>
> = async () => {
  return {
    props: {},
  };
};

export default HomePage;
