/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';

export const ids = {

  searchInput: 'searchInput',
  shopByDepartmentSection: 'shop-by-department-section',
  limitedOfferSection: 'limited-offer-section',

};

export default class LandingPagePage {




  get searchInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.searchInput));
  }

  get firstSuggsetion() {
    return cy.get('.SearchField-module_suggestion__3zuQP', {includeShadowDom : false});
  }

  clickFirstSuggestion() {
    return this.firstSuggsetion.then(($suggestions) => {
      if ($suggestions.length > 0) {
        cy.wrap($suggestions[0]).click({ force: true });
      }
    });
  }

  get shopByDepartmentSection() {
    return cy.get(Utils.formatDataTestIdSelector(ids.shopByDepartmentSection));
  }



  get limitedOfferSection() {
    return cy.get(Utils.formatDataTestIdSelector(ids.limitedOfferSection));
  }

  get limitedOfferCarrousel() {
    return this.shopByDepartmentSection.get('.carousel-root');
  }

  get shopByDepartmentCarrousel() {
    return this.shopByDepartmentSection.find('.carousel-root');
  }

  get shopByDepartmentTitle() {
    return this.shopByDepartmentSection.get('h4');
  }

  get limitedOfferTitle() {
    return this.limitedOfferSection.get('h4');
  }

  clickOnShopDepartment() {
    return this.shopByDepartmentCarrousel.find('div.Card-module_image__1FKdX').click({ force: true, multiple: true });
  }

  scrollToBottom() {
    return cy.get('#container').scrollTo('bottom');
  }

  clickLimitesOffersDepartmentButton() {
    return this.limitedOfferCarrousel.find('button.Button-module_button__2X_Ir').click({ force: true, multiple: true });
  }
 


}
